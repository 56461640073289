<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 表头 -->
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>商品名称：</label>
                  <el-input class="width-220"  v-model="title" placeholder="商品名称" clearable></el-input>
               </el-row>
               <!-- <el-row class="search-item">
                  <label>素材类别：</label>
                  <el-select v-model="typeQ"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in typeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>素材类别：</label>
                  <el-select v-model="typeQ"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in typeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row> -->
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient"  type="primary"  @click="handleAddEdit('add')"><span>发布商品</span></el-button>
                  <!-- <el-button class="bg-gradient"  type="primary"  @click="handleSort(id,'OFFSHELF')"><span>批量下架</span></el-button>-->
               </el-row>
            </div>
            <el-table
                  ref="mainTable"
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  border fit
                  :stripe="true"
                  >
               <el-table-column prop="number" label="序号" width="80">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="商品封面" min-width="80">
                  <template slot-scope="scope">
                     <img v-if="scope.row.coverPath" style="width: 35px;height: 35px" :src="scope.row.coverPath" alt="">
                     <img v-else @click="handleDetail(scope.row.id)" src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="35" height="35" class="suffix-cursor v-align-middle" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="title" label="商品名称" min-width="120"></el-table-column>
               <!--<el-table-column prop="x" label="一级目录"></el-table-column>
               <el-table-column prop="x" label="二级目录"></el-table-column>-->
               <el-table-column label="商品状态">
                  <template slot-scope="scope">
                     {{ scope.row.status | filterStatus(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="price" label="价格" min-width="70"></el-table-column>
               <el-table-column prop="quantity" label="总库存" min-width="70"></el-table-column>
               <el-table-column prop="x" label="总销量" min-width="70"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="200" sortable fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" @click="handleAddEdit('edit', scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                     <el-button v-if="scope.row.status !== '已上架'" @click="handleSort(scope.row.id, 'ONSHELF')" type="text" >
                        <el-link type="primary">上架</el-link>
                     </el-button>
                     <el-button v-else @click="handleSort(scope.row.id, 'OFFSHELF')" type="text" style="color: #F56C6C;" >
                        <el-link type="danger">下架</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="ChangeRow" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
   </div>
</template>

<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   export default {
      name: 'goodsList',
      data(){
         return{
            that: this,
            tableData: [],      // 表格数据
            loading: true,      // 加载中效果
            isShow: false,      // 是否显示弹窗
            total: 0,           // 总条数
            operateType: true,  // 操作类型
            /* 查询参数 */
            page: 1,            // 当前页
            limit: 0,          // 每页显示数
            status: '',         // 商品状态
            title: '',          // 商品名称
            /* 提示文本 */
            del_success: "删除成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
            confirm_up: '确定上架？',
            confirm_down: '确定下架？',
            up_success: '上架成功！',
            down_success: '下架成功！'
         }
      },
      computed: {
         ...mapState(['dictData','hotelInfo'])
      },
     watch: {
       hotelInfo: {
         handler(newVal, oldVal) {
           if(newVal.id !== oldVal.id) {
             this.hotelId = this.hotelInfo.id
             this.getGoods()
           }
         },
       }
     },
      beforeRouteEnter(to, from, next){
         if (from.name === 'goodsAddEdit'){
            next(vm => {
               vm.getGoods()
            })
            return
         }
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'goodsAddEdit'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         getDict(['goods-status'])
         this.getGoods()
      },
      methods: {
         // 获取商品列表
         getGoods(){
            const url = urlObj.getGoodsPaging + `?page=${this.page}&limit=${this.limit}`
            const param = {
               status: this.status,
               title: this.title,
               hotelId: this.hotelInfo.id
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
                  this.tableData.forEach(item => {
                     item.coverPath = item.coverPath && window.getResourcesHost() + item.coverPath
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 查询商品状态
         queryState(val){
            this.status = val
            this.getGoods()
         },
         // 搜索商品
         handleQuery(bool){
            if (bool) return this.getGoods()
            this.status = ''
            this.title = ''
            this.getGoods()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getGoods()
         },
         // 改变列表行数
         ChangeRow(num){
            this.limit = num
            this.getGoods()
         },
         // 添加/编辑商品
         handleAddEdit(act, row){
            if (act === 'edit') sessionStorage.setItem('goodsInfo', JSON.stringify(row))
            this.$router.push({path: '/goods_add_Edit', query: {act}})
         },
         // 查看商品详情
         handleDetail(id){
            this.$router.push({path: '/goods_detail', query: {id}})
         },
         // 上下架商品
         handleSort(id, status){
            this.$confirm(status === 'ONSHELF' ? this.confirm_up : this.confirm_down,this.prompt,{
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let param = {
                  id,
                  status
               }
               let url = urlObj.updateStatus
               this.$axios.post(url, param, 'json').then(() => {
                  this.$message({
                     showClose: true,
                     message: status === 'ONSHELF' ? this.up_success : this.down_success,
                     type: 'success'
                  })
                  this.getGoods()
               }).catch((error) => {
                  console.log(error)
               })
            })
         },
         // 删除商品
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let url = urlObj.delGoods + `/${id}`
               this.$axios.post(url, {}).then(() => {
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getGoods()
               }).catch((error) => {
                  console.log(error)
               })
            })
         }
      },
      filters: {
         filterStatus(val, that) {
            if (val && that.dictData['goods-status']) return that.dictData['goods-status'][val]
         }
      }
   }
</script>
<style scoped></style>
